<template>
	<div class="policy-detail-container">
		<div class="message-top">
			<span class="span-back pointer" @click="goback">返回</span>
			<span>|</span>
			<!-- 文本主题 -0位消息通知 1为系统公告 2为订阅 -->
			<span>{{collectType}}</span>
		</div>
		<!-- 通知详情 -->
		<div class="flex-column flex-start detail-left">
			<div class="detail-title">
				<h1>{{ itemData.name }}</h1>
				<div class="flex-row-between title-wrap">
					<p>{{ itemData.areaname }}</p>
					<p>时间：{{ itemData.createTime }}</p>
				</div>
			</div>
			<div class="detail-content" v-html="itemData.content"></div>

			<!-- <div class="attachment-container">
            <p>相关附件</p>
          </div> -->
			<img class="bottom-img" src="@/assets/images/detail_bottom_icon.png" />
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				id: "",
				itemData: {
					name:"无",
					areaname:"无",
					createTime:"无",
					content:"无",
				},
				//collectType 对应 type
				//1 通知 -> 2通知公告
				// 2 政策 -> 1项目政策
				// 3 信息公示 -> 3公开公示
				type: 0, //请求事项列表传的type参数
				collectType: '', //收藏接口传的type参数
				collectItemId: ""
			};
		},
		created() {
			const that = this;
			const id = that.$route.params.id; // 接收的参数ID
			that.id = id;
			that.type = that.$route.query.type;
			that.getNoticeInfo(id);
			//collectType 对应 type
			// 文本主题 -0位消息通知 1为系统公告 2为订阅 
			let type = this.type
			console.log("type" + type)
			// this.collectType = type
			switch (type) {
				case '0':
					this.collectType = '消息正文'
					break
				case '1':
					this.collectType = '公告正文'
					break
				case '2':
					this.collectType = '订阅详情'
					break
			}
		},
		methods: {
			/**
			 * 公告详细信息
			 * @param {string} id 传项目id
			 */
			getNoticeInfo(id) {
				let that = this;
				if (id) {
					this.$httpApi.get(`/dev-api/document/${id}`, {}).then(res => {
						console.log("公告详细信息:", res);
						if (res.code == 200) {
							that.itemData = res.data;
						}
					})
					.catch((err) => {
					  
					});
				}
			},
			// 返回到上一级目录
			goback(){
				this.$router.push({
				  path: "/workplace/message"
				});
				
			}

		},
	};
</script>

<style lang="scss" scoped>
	.policy-detail-content {
		margin-top: 50px;
	}

	.detail-wrap {
		width: 86%;
		align-items: flex-start;

		/* 左右 */
		.detail-left {
			flex: 1;
			align-self: flex-start;
			margin-right: 30px;
			overflow: hidden;
		}

		.detail-right {
			width: 300px;
		}
	}

	.detail-title {
		color: #333;
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		text-align: center;
		margin-top: 50px;
		h1 {
			width: 100%;
			align-self: flex-start;
			padding-bottom: 10px;
			border-bottom: 0.1875rem solid #0d1c28;
			// margin-bottom: 20px;
		}

		p {
			padding: 10px 0;
		}

		.title-wrap {
			width: 100%;
			height: 36px;
			color: #aaaaaa;
		}
	}
	
	.detail-content {
		line-height: 28px;
		font-size: 14px;
		margin: 20px 0;
		overflow: hidden;

		p {
			text-indent: 2rem;
		}
	}


	/* 底部图片 */
	.bottom-img {
		margin-top: 20px;
		width: 100%;
	}
	// 顶部导航栏
	.message-top{
		span {
			padding: 0 10px;
			font-weight: 700;
		}
		.span-back{
			color: #2D81FF;
			font-weight: 500;
		}
	}
</style>
